/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Checkbox, Select } from 'antd';
import CardComponent from '../../../components/organism/card/card';

import { GET, POST } from '../../../components/Utils/requete';

import formatSelect from '../../../components/Utils/FormatSelect';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';
import dayjs from 'dayjs';

const EleveEvaluation = (props) => {
    let { id } = useParams();
    const css = useStylesMaterielUIGlobal();
    const [AnneeScolaireListe, setAnneeScolaireListe] = useState([]);
    const [AnneeScolaire, setAnneeScolaire] = useState();
    const [listEval, setListEval] = useState([]);
    const [parametreGeneral, setParametreGeneral] = useState([]);

    async function recupListeAnneeScolaire() {
        const response = await GET('/api/anneesScolaire/list');
        if (response.success) setAnneeScolaireListe(formatSelect(response.data, 'idAnneeScolaire', 'libelleAnneeScolaire'));
        RecupDataEval(response.data[0].idAnneeScolaire);
    }

    async function RecupDataEval(idAnneeScolaire) {
        setAnneeScolaire(idAnneeScolaire);
        const response = await GET(`/api/evaluation/listEval/${id}?idAnneeScolaire=${idAnneeScolaire}`);
        if (!response.success) {
            setListEval([]);
            return props.notification({ message: "Aucune évaluation n'a été créée pour cette année" });
        }
        setListEval(response.data);
        const parametre = await GET(`/api/parametres/list?categorie=general`);
        setParametreGeneral(parametre.data);
        if (response.data.length !== 0) {
            const date1 = parametre.data.filter((e) => e.cle === 'ouvertureEvalSemestre1');
            const date2 = parametre.data.filter((e) => e.cle === 'ouvertureEvalSemestre2');
            if (!response.data[0].validationLectureParentSemestre1 && dayjs().isAfter(dayjs(date1[0].valeur)))
                validationEval('semestre1', response.data[0].idEvaluation, idAnneeScolaire);
            if (!response.data[0].validationLectureParentSemestre2 && dayjs().isAfter(dayjs(date2[0].valeur)))
                validationEval('semestre2', response.data[0].idEvaluation, idAnneeScolaire);
        }
    }

    function dateOuverture(params) {
        const date = parametreGeneral.filter((e) => e.cle === params);
        if (date.length === 1) return date[0].valeur;
    }
    async function validationEval(semestre, idEvaluation, idAnneeScolaire) {
        const data = {
            idEvaluation: idEvaluation,
            fk_eleve: id,
            fk_anneeScolaire: idAnneeScolaire,
            validationLectureParentSemestre1: 0,
            validationLectureParentSemestre2: 0,
        };
        if (semestre === 'semestre1') data.validationLectureParentSemestre1 = 1;
        if (semestre === 'semestre2') {
            data.validationLectureParentSemestre1 = 1;
            data.validationLectureParentSemestre2 = 1;
        }
        await POST(`/api/evaluation/validationEval`, data);
    }
    useEffect(() => {
        recupListeAnneeScolaire();
    }, []);
    return (
        <div className=" ml7 mt5 mr7 overflowAuto h80 " style={{ opacity: '0.9' }}>
            <CardComponent className={[css.mt2, css.pl1, css.pr1, css.pb1]}>
                <div className="flex spaceBetween mt2">
                    <div className="w35 textRight alignICenter flex xs_block xs_textLeft">
                        <label className="xs_inlineBlock">Année Scolaire</label>
                        <Select
                            className="nomargin ml30px w50"
                            value={AnneeScolaire}
                            onChange={(values) => RecupDataEval(values)}
                            options={AnneeScolaireListe}
                        />
                    </div>
                </div>
                {dayjs().isAfter(dayjs(dateOuverture('ouvertureEvalSemestre1'))) ? (
                    <div className="mt5 ">
                        {listEval.length !== 0 &&
                            listEval.map((row, i) => (
                                <div className="mb2" key={i}>
                                    {row.atelier.map((atelier, index) => (
                                        <div key={index}>
                                            <hr className="w100"></hr>
                                            <div className="flex alignICenter">
                                                <div className="flex directionColumn w30">
                                                    <span>Atelier : {atelier.libelleType}</span>
                                                    <div className="flex alignICenter mt1">
                                                        <span className="mr1">Enseignant :</span>
                                                        <span>
                                                            {atelier.nomPrenomUtilisateur.map((enseignant, e) => (
                                                                <span key={e}>
                                                                    {enseignant.nomPrenomUtilisateur} <br />
                                                                </span>
                                                            ))}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="w35">
                                                    <h3>Semestre 1</h3>
                                                    <span>{atelier.semestre1}</span>
                                                </div>
                                                {dayjs().isAfter(dayjs(dateOuverture('ouvertureEvalSemestre2'))) && (
                                                    <div className="w35">
                                                        <h3>Semestre 2</h3>
                                                        <span>{atelier.semestre2}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className="mt5 mb2">
                        <span>Les évaluations de l'année en cours ne sont pas encore ouvert à la consultation.</span>
                    </div>
                )}
            </CardComponent>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EleveEvaluation);
